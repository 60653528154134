import React from "react"

const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <span>
      Search:{" "}
      <input
        value={filterValue || ""}
        onChange={event => setFilter(event.target.value)}
      />
    </span>
  )
}

export default ColumnFilter
