import React, { useMemo, useEffect, useState } from "react"
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table"
import { useQuery, gql } from "@apollo/client"
import { COLUMNS } from "../tables/columns"
// import { fetchCobieData } from "../actions/fetchCobieData"
import API from "../services/api"
import GlobalFilter from "../tables/GlobalFilter"
import ColumnFilter from "../tables/ColumnFilter"

import * as styles from "../css/table.module.css"

const getTableDataQuery = gql`
  query getTableDataQuery {
    getTableData
  }
`

const CobieData = () => {
  const [tableData, setTableData] = useState([])
  const [replaceValue, setReplaceValue] = useState("")

  const {
    loading,
    error,
    data: tableDataGql,
  } = useQuery(getTableDataQuery, {
    pollInterval: 1000 * 60 * 5,
  })

  // console.log(loading)

  const columns = useMemo(() => COLUMNS, [])
  const data = useMemo(() => tableData, [tableData])
  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    }
  }, [])

  const fetchCobieData = async () => {
    const { data } = await API.get(`sce/dfx-model/wpdatatables`)
    setTableData(data)
  }

  useEffect(() => {
    // fetchCobieData()
    if (!loading) {
      console.log(JSON.parse(tableDataGql.getTableData))
      setTableData(JSON.parse(tableDataGql.getTableData))
    }
  }, [loading])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    usePagination
  )

  const { globalFilter, pageIndex, pageSize } = state

  const replaceValueHandler = async () => {
    console.log(state.filters)
    console.log(rows)
    console.log(replaceValue)

    let filteredRows = []
    rows.forEach(row => {
      console.log(row.values)
      filteredRows.push(row.values)
    })

    const postData = {
      filter: state.filters,
      filteredRows,
      replaceValue: replaceValue,
    }
    const { data } = await API.post("sce/dfx-model/replace", postData)
    console.log(data)
  }

  if (Array.isArray(data) && data.length) {
    return (
      <>
        {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <div>
          Replace: {""}
          <input
            value={replaceValue}
            onChange={event => setReplaceValue(event.target.value)}
          />
          <button onClick={replaceValueHandler}>Replace</button>
        </div> */}
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                    <div>
                      {column.canFilter ? (
                        <>
                          <div>{column.render("Filter")}</div>
                          <div>
                            Replace: {""}
                            <input
                              value={replaceValue}
                              onChange={event =>
                                setReplaceValue(event.target.value)
                              }
                            />
                            <button onClick={replaceValueHandler}>
                              Replace
                            </button>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr key={row.id} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
              }}
            />
          </span>
          <select
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            Previous
          </button>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            Next
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
      </>
    )
  }

  return <div>Loading...</div>
}

export default CobieData
